<template>
        <div :style="'height:'+ innerHeight + 'px;vertical-align: bottom;'">
            <div class="bg"></div>
             <div class="e">
                 <Card  style="width:500px;border: 1px solid #eeeeee; " shadow  bordered title="登录">
                    <div style="margin:20px 30px">
                        <Form ref="formInline" :model="formInline" :rules="ruleInline" >
                            <FormItem prop="account">
                                <Input type="text" v-model="formInline.account" placeholder="请输入账户">
                                    <Icon size="18" type="ios-person-outline" slot="prepend"></Icon>
                                </Input>
                            </FormItem>
                            <FormItem prop="password">
                                <Input type="password" v-model="formInline.password" placeholder="请输入密码">
                                    <Icon size="18" type="ios-lock-outline"  slot="prepend"></Icon>
                                </Input>
                            </FormItem>
                            <FormItem>
                                <Button type="primary" @click="handleSubmit('formInline')">登录</Button>
                            </FormItem>
                        </Form>
                    </div>
                </Card>
             </div>
        </div>
</template>
<script>
import { login } from "@/api/index";
import {setCookies} from '@/utils/helper'
    export default {
      data() {
        return {
          innerHeight: 0,
          formInline: {
            account: '',
            password: ''
          },
          ruleInline: {
            account: [
              {required: true, message: '账户不能为空', trigger: 'blur'}
            ],
            password: [
              {required: true, message: '密码不能为空', trigger: 'blur'}
            ],
          }
        }
      },
      mounted() {
        this.restHeight();
        // windowsRise(window, this.restHeight);
      },
      created() {
        var _this = this
        document.onkeydown = function () {
          if (_this.$route.name === '登录') {
            let key = window.event.keyCode
            if (key === 13) {
              _this.handleSubmit('formInline')
            }
          }
        }
      },
      methods: {
        /**修改高度 */
        restHeight() {
          this.innerHeight = window.innerHeight - 2;
        },
        handleSubmit(name) {
          let menu = [
            {
              children: [],
              icon: "md-home",
              id: 12,
              is_menu: 1,
              pid: 0,
              src: "/admin",
              title: "开单",
              unique_auth: "index"
            },
            {
              children: [],
              icon: "md-home",
              id: 13,
              is_menu: 1,
              pid: 0,
              src: "/admin/chatcontent",
              title: "用户信息",
              unique_auth: "chatcontent"
            },
            {
              children: [],
              icon: "md-home",
              id: 14,
              is_menu: 1,
              pid: 0,
              src: "/admin/notice",
              title: "公告",
              unique_auth: "notice"
            }
          ]
          this.$store.commit('setMenus', menu)

          this.$refs[name].validate((valid) => {
              if (valid) {
                login(this.formInline).then(res=>{
                  setCookies('token', res.data.token)
                  this.$store.commit('setUserInfo', res.data.user)
                  this.$store.commit('setSmsCount', res.data.sms_count)
                  this.$Message.success(res.msg);
                  this.$router.push({path: '/admin'})
                }).catch(res=>{
                  this.$Message.error(res.msg);
                })
              } else {
                  this.$Message.error('数据不能为空');
              }
          })
        }
      }
    }
</script>
<style scoped>
.ivu-icon{
    width:30px
}
.e{
    margin-top:-20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 999;
}
.bg{
    background: url("../../assets/14.jpg") no-repeat;
    background-size: 100% 100%;
    width:100%;
    height:100%;position:absolute;z-index:1;
}
</style>
